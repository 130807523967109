const DealerHelper = {
  getDealersWithinBounds: (map, activeCategory, setDealers, isFirstFetch, setIsFirstFetch) => {
    if (activeCategory !== defaultCategoryValue) {
      const options = {
        method: 'POST',
        body: JSON.stringify({
          productCategoryPreciousId: activeCategory,
          bounds: getBoundsForDealerApi(map),
          isFirstFetch: isFirstFetch,
          zoomLevel: map.getZoom(),
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      };
      fetch('/api/sitecore/dealerlocator/GetDealersWithinBounds', options)
        .then((data) => data.json())
        .then((data) => {
          setDealers(data);

          if (isFirstFetch) {
            fitBounds(map, data);
          }
        });

      setIsFirstFetch(false);
    } else {
      setDealers([]);
      setIsFirstFetch(true);
    }
  },
  getOnlineDealers: (activeCategory, isFirstFetch, setOnlineDealers) => {
    if (activeCategory !== defaultCategoryValue && isFirstFetch) {
      fetch('/api/sitecore/dealerlocator/GetOnlineDealers')
        .then((data) => data.json())
        .then((data) => setOnlineDealers(data));
    }
  },
  getDealer: (dealerId, setIsSingleDealer, setDealers, setSelectedDealer, setUserCoords) => {
    setIsSingleDealer(true);

    const options = {
      method: 'POST',
      body: JSON.stringify({
        dealerId: dealerId,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    };
    fetch('/api/sitecore/dealerlocator/GetDealer', options)
      .then((data) => data.json())
      .then((data) => {
        setDealers([data]);
        setSelectedDealer(data);
        setUserCoords({
          lat: data.Coordinates.Latitude,
          lng: data.Coordinates.Longitude,
        });
      });
  },
};

const getBoundsForDealerApi = (map) => {
  let bounds = map.getBounds();
  var swLat = bounds.getSouthWest().lat();
  var swLon = bounds.getSouthWest().lng();
  var neLat = bounds.getNorthEast().lat();
  var neLon = bounds.getNorthEast().lng();

  var center = map.getCenter();
  var northEast = { Latitude: neLat, Longitude: neLon };
  var southWest = { Latitude: swLat, Longitude: swLon };

  return {
    NorthEast: northEast,
    SouthWest: southWest,
    Center: {
      Latitude: center.lat(),
      Longitude: center.lng(),
    },
  };
};

const defaultCategoryValue = 'DEFAULT_CATEGORY';

const fitBounds = (map, dealerList) => {
  dealerList = dealerList || dealers;
  let outsideBounds = [];

  dealerList.forEach((dealer) => {
    var coord = new google.maps.LatLng(dealer.Coordinates.Latitude, dealer.Coordinates.Longitude);
    var inbounds = map.getBounds().contains(coord);

    if (!inbounds) outsideBounds.push(dealer);
  });

  if (outsideBounds.length > 0) {
    map.setZoom(map.getZoom() - 1);
    fitBounds(map, dealerList);
  }
};

export default DealerHelper;
