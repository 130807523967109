import React, { memo } from 'react';

const InfoModal = ({ translations }) => {
  return (
    <div className="modal fade" id="infoModal" tabIndex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body" dangerouslySetInnerHTML={{ __html: translations.InfoModalText }}></div>
        </div>
      </div>
    </div>
  );
};

export default memo(InfoModal);
