const GeolocationHelper = {
  getCoordsForCurrentMarket: () => {
    let coords = {
      lat: window.dealerLocator.Coordinates.Latitude,
      lng: window.dealerLocator.Coordinates.Longitude,
    };
    return coords;
  },
  getUserLocation: (userCoords, SetUserCoords, overrideUserCoords) => {
    if (!userCoords || overrideUserCoords) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            SetUserCoords(formatUserCoords(position.coords));
          },
          (ex) => {
            switch (ex.code) {
              case ex.PERMISSION_DENIED:
                console.log('User denied the request for Geolocation. Message: ' + ex.message);
                // TODO: Gelocation denied message
                break;
              case ex.POSITION_UNAVAILABLE:
                console.log('Location information is unavailable. Message: ' + ex.message);
                break;
              case ex.TIMEOUT:
                console.log('The request to get user location timed out. Message: ' + ex.message);
                break;
              case ex.UNKNOWN_ERROR:
                console.log('An unknown error occurred. Message: ' + ex.message);
                break;
            }
          }
        );
        SetUserCoords(GeolocationHelper.getCoordsForCurrentMarket());
      }
      SetUserCoords(GeolocationHelper.getCoordsForCurrentMarket());
    }
  },
};

const formatUserCoords = (coords) => {
  let coordsObj = { lat: coords.latitude, lng: coords.longitude };
  return coordsObj;
};

export default GeolocationHelper;
