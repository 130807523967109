import React from 'react';
import Dealer from './Dealer';

const DealerList = (props) => {
  return (
    props.dealers.length > 0 && (
      <div className="panel results">
        <ol>
          {props.dealers.map((dealer, index) => {
            return (
              <Dealer
                dealer={dealer}
                setSelectedDealer={props.setSelectedDealer}
                activeCategoryName={props.activeCategoryName}
                key={index}
              />
            );
          })}
        </ol>
      </div>
    )
  );
};

export default DealerList;
