import React from 'react';
import Assortment from './Assortment';
import Service from './Service';

const DealerDetails = ({
  dealer,
  categories,
  setSelectedDealer,
  setShowPartnerModal,
  userCoords,
  translations,
  activeCategoryName,
}) => {
  const getAssortments = () => {
    let ids = dealer.DealerAssortments.map((ass) => ass.AssortmentId);
    let assortments = categories.filter((cat) => ids.indexOf(cat.AssortmentId.toLowerCase()) !== -1);

    return assortments;
  };

  const shouldShowDealerRating = () => {
    return dealer.PartnerRating === 10 || dealer.PartnerRating === 11 ? true : false;
  };

  const handleClick = (clickType, url, event) => {
    event.preventDefault();
    try {
      google.tagmanager.add({
        dealerLocatorClickOutType: clickType,
        dealerLocatorSelectedCategory: activeCategoryName,
        dealerLocatorDealerName: dealer.Name,
        dealerLocatorDealerType: 'regular',
        dealerLocatorDealerCity: dealer.City,
        event: 'dealerLocatorClickOut',
      });
    } catch (error) {}
    window.open(url, clickType == 'website' ? '_blank' : undefined);
  };

  return (
    <div className="panel dealer">
      <i className="fas fa-angle-left ng-scope"></i>
      <a href="#/" className="back-to-list" onClick={() => setSelectedDealer(null)}>
        {translations.BackToList}
      </a>
      <div itemScope="" itemType="http://schema.org/Organization">
        {shouldShowDealerRating() && (
          <div className="rating-special">
            <div>
              <span className="rating-special-label">
                {dealer.PartnerRating === 11 ? translations.Rating11Label : translations.Rating10Label}
              </span>{' '}
              {dealer.PartnerRating === 10 && (
                <span className="fa-stack" onClick={() => setShowPartnerModal(true)}>
                  <i className="fas fa-info fa-stack-1x"></i>
                  <i className="far fa-circle fa-stack-2x"></i>
                </span>
              )}
            </div>
          </div>
        )}
        <h1 className="headline1 dealer-name" id="dealer-name" itemProp="name">
          {dealer.Name}
        </h1>
        <div className="address" itemProp="address" itemScope="" itemType="http://schema.org/PostalAddress">
          <span itemProp="addressLocality">{dealer.StreetAddress}</span>
          <br />
          <span itemProp="postalCode">{dealer.ZipCode}</span>{' '}
          <span itemProp="addressLocality" className="dealer-city" id="dealer-city">
            {dealer.City}
          </span>
          <br />
          <a
            target="_blank"
            href={`http://maps.google.com/maps?saddr=${userCoords.lat},${userCoords.lng}&daddr=${dealer.Coordinates.Latitude},${dealer.Coordinates.Longitude}`}
          >
            {translations.GetDirections}
          </a>
        </div>
        <div>
          <a href={`tel:${dealer.Phone}`} onClick={(e) => handleClick('phone', `tel:${dealer.Phone}`, e)}>
            <span itemProp="telephone">{dealer.Phone}</span>
          </a>
          <br />
        </div>
        <div>
          <a
            href={`mailto:${dealer.Email}`}
            className="dealer-email"
            onClick={(e) => handleClick('email', `mailto:${dealer.Email}`, e)}
          >
            <span itemProp="email">{dealer.Email}</span>
          </a>
          <br />
        </div>
        <div>
          <a
            href={dealer.Url}
            itemProp="url"
            className="dealer-url"
            onClick={(e) => handleClick('website', dealer.Url, e)}
          >
            {dealer.Url}
          </a>
        </div>
      </div>

      <h2 className="categories-title">{translations.ProductCategoriesTitle}</h2>
      <ul className="categories-list">
        {getAssortments().map((ass, index) => {
          return <Assortment assortment={ass} key={index} />;
        })}
      </ul>

      {dealer.DealerServices.length > 0 && (
        <>
          <h2 className="services-title">{translations.ServicesHeading}</h2>
          <ul className="services-list">
            {dealer.DealerServices.map((service, index) => {
              return <Service service={service} translations={translations.Services} key={index} />;
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default DealerDetails;
