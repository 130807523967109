import React from 'react';

const Dealer = ({ dealer, setSelectedDealer, isOnline, activeCategoryName }) => {
  const shouldShowDealerLabel = () => {
    return dealer.PartnerRating === 10 || dealer.PartnerRating === 11 ? true : false;
  };

  const getDealerLabel = () => {
    return dealer.PartnerRating === 10 ? 'Thule Partner' : dealer.PartnerRating === 11 ? 'Thule Store' : '';
  };

  const handleClick = () => {
    try {
      google.tagmanager.add({
        dealerLocatorClickOutType: 'website',
        dealerLocatorSelectedCategory: activeCategoryName,
        dealerLocatorDealerName: dealer.Name,
        dealerLocatorDealerType: !isOnline ? 'regular' : 'online',
        dealerLocatorDealerCity: dealer.City || '',
        event: 'dealerLocatorClickOut',
      });
    } catch (error) {}
    window.open(dealer.Url, '_blank');
  };

  if (!isOnline) {
    return (
      <li className={shouldShowDealerLabel() ? 'rating-special' : ''}>
        <a
          className="dealer-link"
          href={`#/dealer/${dealer.Name}/${dealer.Id}`}
          onClick={() => setSelectedDealer(dealer)}
        >
          {shouldShowDealerLabel() && <div className="rating-special-label">{getDealerLabel()}</div>}
          <h6 className="dealer-name">
            {dealer.Name}
            <i className="fa-solid fa-angle-right dealer-expand"></i>
          </h6>
          <span className="dealer-city">{dealer.City}</span>
          <br />
        </a>
        <a onClick={handleClick}>{dealer.Url}</a>
      </li>
    );
  } else {
    return (
      <li className={shouldShowDealerLabel() ? 'rating-special' : ''}>
        {shouldShowDealerLabel() && <div className="rating-special-label">{getDealerLabel()}</div>}
        <h6 className="dealer-name">{dealer.Name}</h6>
        <a onClick={handleClick}>{dealer.Url}</a>
      </li>
    );
  }
};

export default Dealer;
