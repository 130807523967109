import React, { memo } from 'react';

const Assortment = ({ assortment }) => {
  return (
    <li>
      <span className="icon-wrapper">
        <i className={assortment?.CssClass}></i>
      </span>
      <span className="text-wrapper ">{assortment.Name}</span>
    </li>
  );
};
export default memo(Assortment);
