import React from 'react';

const PartnerModal = ({ showPartnerModal, setShowPartnerModal, translations }) => {
  return (
    <div className="partner-modal-wrapper">
      <div
        id="partnerModalBackground"
        className={showPartnerModal ? 'show' : 'hide'}
        onClick={() => setShowPartnerModal(false)}
      ></div>
      <div id="partnerModal" className={`d-flex justify-content-center ${showPartnerModal ? 'show' : 'hide'}`}>
        <div className="partner-modal-content">
          <h4>{translations.ModalHeader}</h4>
          <div className="img" style={{ background: `url(${translations.ModalImage})` }}></div>
          <div dangerouslySetInnerHTML={{ __html: translations.ModalText }}></div>
        </div>
        <div className="badge rounded-circle partner-modal-close" onClick={() => setShowPartnerModal(false)}>
          <i className="fas fa-times"></i>
        </div>
        <div className="partner-modal-close-mobile" onClick={() => setShowPartnerModal(false)}>
          <i className="fas fa-times"></i>
        </div>
      </div>
    </div>
  );
};

export default PartnerModal;
