import React, { useEffect, useState, useRef, useCallback } from 'react';
import { GoogleMap, GoogleMarkerClusterer, Marker, useJsApiLoader } from '@react-google-maps/api';
import DealerDetails from './DealerDetails';
import DealerList from './DealerList';
import PartnerModal from './PartnerModal';
import InfoModal from './InfoModal';
import Dealer from './Dealer';
import GeolocationHelper from '../Helpers/GeolocationHelper';
import DealerHelper from '../Helpers/DealerHelper';

const DealerLocator = ({ Categories, Translations }) => {
  const defaultCategoryValue = 'DEFAULT_CATEGORY';
  const defaultZoom = 14;

  const [activeCategory, setActiveCategory] = useState(defaultCategoryValue);
  const [activeCategoryName, setActiveCategoryName] = useState('');
  const [dealers, setDealers] = useState([]);
  const [onlineDealers, setOnlineDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [showList, setShowList] = useState(true);
  const [userCoords, setUserCoords] = useState(GeolocationHelper.getCoordsForCurrentMarket());
  const [addressQuery, setAddressQuery] = useState('');
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [isSingleDealer, setIsSingleDealer] = useState(false);

  var tilesLoadedOnce = false;

  const mapRef = useRef();
  const geoCoderRef = useRef();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB7bljaTV8cr6kXZnOoDLJ8n0W2WFgfD-c',
  });

  const onMapLoaded = useCallback((map) => {
    mapRef.current = map;
    geoCoderRef.current = new google.maps.Geocoder();

    GeolocationHelper.getUserLocation(userCoords, setUserCoords, true);
  }, []);

  const onTilesLoaded = useCallback(() => {
    if (tilesLoadedOnce) return;

    tilesLoadedOnce = true;

    let hash = window.location.hash;
    if (hash.indexOf('/dealer/') !== -1) {
      let entries = hash.split('/');
      DealerHelper.getDealer(
        entries[entries.length - 1],
        setIsSingleDealer,
        setDealers,
        setSelectedDealer,
        setUserCoords
      );
      window.location.hash = '/';
    }
    if (hash.indexOf('/category/') !== -1) {
      let entries = hash.split('/');
      let category = Categories.find((x) => x.UrlEncodedName === entries[entries.length - 1]);
      if (category) {
        setActiveCategory(category.AssortmentId);
      }
    }
  }, []);

  useEffect(() => {
    if (activeCategory !== defaultCategoryValue) {
      try {
        google.tagmanager.add({
          dealerLocatorCategoryChosen: Categories.find((cat) => cat.AssortmentId === activeCategory)?.EnglishName,
          event: 'dealerLocatorCategoryChoice',
        });
      } catch (error) {}
    }
    setActiveCategoryName(Categories.find((cat) => cat.AssortmentId === activeCategory)?.EnglishName || '');
    setIsSingleDealer(false);
    setIsFirstFetch(true);
    DealerHelper.getDealersWithinBounds(mapRef.current, activeCategory, setDealers, isFirstFetch, setIsFirstFetch);
    DealerHelper.getOnlineDealers(activeCategory, isFirstFetch, setOnlineDealers);
  }, [activeCategory]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setCenter(userCoords);
      mapRef.current.setZoom(defaultZoom);
    }
  }, [userCoords]);

  const showNoHitsMessage = () => {
    return activeCategory === defaultCategoryValue && !selectedDealer && dealers.length === 0;
  };

  const geolocateUser = () => {
    GeolocationHelper.getUserLocation(userCoords, setUserCoords, true);
    DealerHelper.getDealersWithinBounds(mapRef.current, activeCategory, setDealers, isFirstFetch, setIsFirstFetch);
    setIsFirstFetch(true);
  };

  const getLocationFromSearch = (query) => {
    geoCoderRef.current.geocode({ address: query }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        let coords = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
        let place = results[0].address_components[0].long_name;
        setAddressQuery(place);
        setIsFirstFetch(true);
        mapRef.current.setCenter(coords);
        mapRef.current.setZoom(defaultZoom);
      }
    });
  };

  const setCategoryUrl = (activeCategory) => {
    if (activeCategory === defaultCategoryValue) {
      window.location.hash = '/';
    } else {
      let categoryData = Categories.find((cat) => cat.AssortmentId === activeCategory);
      if (!categoryData) return;
      window.location.hash = `/category/${categoryData.UrlEncodedName}`;
    }
  };

  const getMapClasses = () => {
    if (document.querySelector('.mobile-check').offsetParent === null) return '';

    return showList ? 'mobile-list' : 'mobile-map';
  };

  return (
    <>
      <div className="thule-container--full-width header">
        <h1>
          {Translations.MainHeader}{' '}
          <span className="fa-stack" data-bs-toggle="modal" data-bs-target="#infoModal">
            <i className="fas fa-info fa-stack-1x"></i>
            <i className="far fa-circle fa-stack-2x"></i>
          </span>
        </h1>
      </div>
      <div className="thule-container--full-width dealer-locator-wrapper">
        <div className="sidebar">
          <div className="panel search">
            <select
              className="form-control"
              value={activeCategory}
              onChange={(e) => {
                setActiveCategory(e.target.value);
                setCategoryUrl(e.target.value);
              }}
            >
              <option value={defaultCategoryValue}>{Translations.CategoryListFieldPlaceholder}</option>
              {Categories.map((cat, index) => (
                <option key={index} value={cat.AssortmentId}>
                  {cat.Name}
                </option>
              ))}
            </select>
            <div className="input-group">
              <span className="input-group-btn">
                <button className="btn btn-sm thule-btn-default btn__geolocateUser" type="button" onClick={() => geolocateUser()}>
                  <span className="btn-text">
                    <i className="fa-solid fa-location-crosshairs"></i>
                    <span className="sr-only">Find my current location</span>
                  </span>
                </button>
              </span>
              <label className="sr-only" htmlFor="dl-search-field">
                Search for city or street
              </label>
              <input
                name="search"
                type="search"
                className="search-text dl-search-field form-control"
                placeholder={Translations.SearchFieldPlaceholder}
                value={addressQuery}
                onChange={(e) => {
                  setAddressQuery(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') getLocationFromSearch(addressQuery);
                }}
                id="dl-search-field"
              />
            </div>
          </div>
          {!selectedDealer && (
            <div className="panel mobile-switch d-block d-md-none">
              {showList && (
                <div className="view-map">
                  <a
                    onClick={() => {
                      setShowList(false);
                    }}
                  >
                    <i className="fas fa-map-marker-alt view-map-pin"></i>
                    {Translations.ViewOnMap}
                  </a>
                </div>
              )}
              {!showList && (
                <div className="view-list">
                  <a
                    onClick={() => {
                      setShowList(true);
                    }}
                  >
                    <i className="fas fa-list-ol view-list-pin"></i>
                    {Translations.ViewInList}
                  </a>
                </div>
              )}
            </div>
          )}
          {selectedDealer && (
            <DealerDetails
              dealer={selectedDealer}
              categories={Categories}
              setSelectedDealer={setSelectedDealer}
              setShowPartnerModal={setShowPartnerModal}
              userCoords={userCoords}
              translations={Translations}
              activeCategoryName={activeCategoryName}
            />
          )}
          {!selectedDealer && showList && (
            <DealerList
              dealers={dealers}
              setSelectedDealer={setSelectedDealer}
              activeCategoryName={activeCategoryName}
            />
          )}
          {showNoHitsMessage() && <div className="panel info">{Translations.NoHitsMessage}</div>}
          {!selectedDealer && showList && dealers.length > 0 && (
            <div className="panel online">
              <div className="online-dealers-title">{Translations.OnlineDealersTitle}</div>
              <ul>
                {onlineDealers &&
                  onlineDealers.map((dealer, index) => {
                    if (dealer.DealerAssortments.find((x) => x.AssortmentId === activeCategory?.toLowerCase()))
                      return (
                        <Dealer dealer={dealer} isOnline={true} activeCategoryName={activeCategoryName} key={index} />
                      );
                  })}
              </ul>
            </div>
          )}
        </div>
        <div className={`map-container ${getMapClasses()}`}>
          {isLoaded && (
            <GoogleMap
              id="map"
              center={userCoords}
              zoom={defaultZoom}
              options={{
                zoomControlOptions: { position: 7 },
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                panControl: false,
                styles: [
                  {
                    featureType: 'poi',
                    stylers: [
                      {
                        visibility: 'off',
                      },
                    ],
                  },
                  {
                    featureType: 'poi.park',
                    stylers: [
                      {
                        visibility: 'on',
                      },
                    ],
                  },
                ],
              }}
              onLoad={onMapLoaded}
              onTilesLoaded={onTilesLoaded}
              onIdle={() => {
                if (!isSingleDealer)
                  DealerHelper.getDealersWithinBounds(
                    mapRef.current,
                    activeCategory,
                    setDealers,
                    isFirstFetch,
                    setIsFirstFetch
                  );
              }}
            >
              <GoogleMarkerClusterer
                options={{
                  renderer: {
                    render: ({ position, count }) => {
                      return new google.maps.Marker({
                        position,
                        icon: {
                          url: window.dealerLocator.MultipleMarkerImage1Url,
                          scaledSize: new google.maps.Size(45, 45),
                        },
                        label: {
                          text: String(count),
                          color: '#ffffff',
                          fontSize: '8px',
                        },
                        zIndex: 1000 + count,
                      });
                    },
                  },
                }}
              >
                {(clusterer) =>
                  dealers.map((dealer, index) => (
                    <Marker
                      key={dealer.Id}
                      position={{ lat: dealer.Coordinates.Latitude, lng: dealer.Coordinates.Longitude }}
                      icon={
                        selectedDealer?.Id === dealer.Id
                          ? window.dealerLocator.LargeMarkerImageUrls[index]
                          : window.dealerLocator.MarkerImageUrls[index]
                      }
                      onClick={() => {
                        setSelectedDealer(dealer);
                        window.location.hash = `/dealer/${dealer.Name}/${dealer.Id}`;
                      }}
                      clusterer={clusterer}
                    />
                  ))
                }
              </GoogleMarkerClusterer>
            </GoogleMap>
          )}
        </div>
      </div>
      <PartnerModal
        showPartnerModal={showPartnerModal}
        setShowPartnerModal={setShowPartnerModal}
        translations={Translations}
      />
      <InfoModal translations={Translations} />
    </>
  );
};

export default DealerLocator;
