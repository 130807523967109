import React, { memo } from 'react';

const Service = ({ service, translations }) => {
  return (
    <li>
      <span className="icon-wrapper"></span>
      <span className="text-wrapper ">{translations[service.ServiceKey]}</span>
    </li>
  );
};
export default memo(Service);
